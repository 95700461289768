import { navigate } from 'gatsby';
import React from 'react';

import { Dropdown } from 'semantic-ui-react';

import { colors, space, radii, fontSizes } from './utils/presets';
import {
  getAvailableVersionNumbers,
  versionNumberFromDocPath,
} from '../../utils';

const VersionSelector = (props: { pathname: string }) => {
  // Get all available version from the static query as well as the current
  // version based on the path.
  const docVersions = getAvailableVersionNumbers();
  let thisVersion = versionNumberFromDocPath(props.pathname);
  if (thisVersion === 'LATEST') {
    thisVersion = docVersions.current;
  }

  const versions = [docVersions.current];
  for (const v of docVersions.others) {
    versions.push(v);
  }
  const options = versions.map(i => ({
    key: i,
    text: i,
    value: i,
    content: i,
  }));

  // Current value depends on the location + the available versions.
  const defaultIndex = versions.indexOf(thisVersion);

  return (
    <div
      css={{
        fontSize: fontSizes[1],
        background: `transparent`,
        border: `none`,
        borderRadius: radii[1],
        color: colors.gray.calm,
        cursor: `pointer`,
        alignItems: `center`,
        paddingLeft: space[2],
        textAlign: `left`,
      }}
    >
      Version:{' '}
      <Dropdown
        inline
        header="Salvus documentation version"
        options={options}
        defaultValue={options[defaultIndex].value}
        css={{
          color: `${colors.mondaic.orange} !important`,
        }}
        onChange={(e, data) => {
          if (data.value != thisVersion) {
            navigate(`/docs/${data.value}`);
          }
        }}
      />
    </div>
  );
};

export default VersionSelector;
